import React from "react"
import "./reset.css"
import Navbar from "./Navbar"
import Footer from "./Footer"
import GlobalStyles from "../styles/GlobalStyles"
import TypographyStyles from "../styles/TypographyStyles"
import Logo from "../images/logo-a2e3eb.png"
import {useStaticQuery,graphql} from "gatsby"
import BackgroundImage from "gatsby-background-image"
import {Helmet} from "react-helmet"

const Bg = ({children,className}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "masao-mask-TomNy2OVtCk-unsplash.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const imageData = data.desktop.childImageSharp.fluid

  return (
    <BackgroundImage preserveStackingContext fluid={imageData} className={className} >{children}</BackgroundImage>
  );
}

const creditsHtml = 'Photo by <a target=\'_blank\' href="https://unsplash.com/@masaomask?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Masao Mask</a> on <a target=\'_blank\' href="https://unsplash.com/@masaomask?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>';

const Layout = ({children, bgImg = null}) => {
  return (
    <>
      <GlobalStyles />
      <TypographyStyles />
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-YWDYLK69PC"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments)}
          gtag('js', new Date());
          gtag('config', 'G-YWDYLK69PC');
        `}</script>
      </Helmet>
      <div className="pageBgWrapper">
        <Bg className="pageBg">
          <Navbar Logo={Logo} />
          <div className="layout">{children}</div>
          <Footer credits={creditsHtml} />
        </Bg>
      </div>
    </>
  )
}

export default Layout
