import React from "react"
import {useStaticQuery,Link,graphql} from "gatsby"
import {
  mainMenuItems,
  footerMenuItems,
  socialMenuItems,
} from "../constants/menu-items"
import styled from "styled-components"
import PropTypes from "prop-types"
import {device} from "../styles/breakpoints"

const Footer = ({Logo, credits}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            authorSite
          }
        }
      }
    `
  )

  const footerData = data.site.siteMetadata

  return (
    <FooterStyles>
      <div className="flex">
        {Logo ? (
          <div className="brand-cont">
            {/* If there is a logo, render this */}
            {Logo && (
              <Link to="/">
                <img src={Logo} alt={`${footerData.title} logo`} />
              </Link>
            )}
          </div>
        ) : null}

        {socialMenuItems || footerMenuItems ? (
          <div className="menus-cont">
            {/* If footer menu items are being imported, render this */}
            {footerMenuItems && (
              <ul className="footer-menu">
                {footerMenuItems.map((item,index) => (
                  <li key={`footerMenuItem${index}`}>
                    <Link to={item.path}>{item.title}</Link>
                  </li>
                ))}
              </ul>
            )}

            {/* If social menu items are being imported, render this */}
            {socialMenuItems && (
              <ul className="footer-menu socials">
                {socialMenuItems.map((item,index) => {
                  return (
                    <li key={index}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        alt={item.name}
                      >
                        {item.icon}
                        <span className="sr-only">{item.name}</span>
                      </a>
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        ) : null}

        <div className="copy-cont">
          <ul className="copy">
            <li>&copy; {new Date().getFullYear()}</li>
            <li>{footerData.author}</li>
            {credits ? <li><div dangerouslySetInnerHTML={{__html: credits}}></div></li> : null}
            {/*<li>Encouraged by <a target='_blank' href='https://massbridge.co/'>massbridge.co</a></li>*/}
          </ul>
        </div>
      </div>
    </FooterStyles>
  )
}

Footer.propTypes = {
  Logo: PropTypes.string,
}

const FooterStyles = styled.footer`
  padding: calc(var(--spacing) * 2);
  background-color: var(--primaryColor);
  color: var(--charcoal);

  .flex {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    @media ${device.tablet} {
      flex-direction: column;
      justify-content: center;
    }
  }

  .telephone,
  address,
  li {
    font-size: var(--footerMenuItem);
  }

  address {
    font-style: normal;
    margin-bottom: var(--spacing);
  }

  li {
    list-style: none;
    padding-left: 0;
    margin-top: 5px;
    margin-bottom: 5px;

    @media ${device.tablet} {
      margin-top: 10px;
      margin-bottom: var(--spacing);
    }
  }

  a {
    text-decoration: none;
    transition: var(--transMed);

    &:hover {
      color: #000;
    }
  }

  @media ${device.tablet} {
    padding-top: calc(var(--spacing) * 4);
    padding-bottom: calc(var(--spacing) * 2);
  }

  .brand-cont,
  .brand-cont img {
    margin-bottom: calc(var(--spacing) * 2);
  }

  .brand-cont {
    width: 100%;

    @media ${device.tablet} {
      width: 25%;
    }

    a {
      display: inline-block;
    }

    img {
      width: 100%;
    }
  }

  .menus-cont {
    width: 100%;
    border-top: 4px solid rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    
    @media ${device.mobileL} {
      width: 100%;
      border-top: 0;
    }
  }

  .footer-menu {
    padding: 0;
    width: 50%;
    margin-top: calc(var(--spacing) * 2);
    margin-bottom: 0;

    > *:first-child {
      margin-top: 0;
    }

    li {
      margin-bottom: var(--spacing);
      
      &:last-child {
        margin-bottom: 0;
        padding-right: 20px;
      }
    }

    @media ${device.mobileL} {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      
      li {
        display: inline;
        padding-left: 22px;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .socials {
    display: flex;

    li {
      margin-top: 0;
      padding: 0;
      margin-right: var(--spacing);

      &:last-child {
        margin-right: 0;
      }
    }

    a {
      font-size: 26px;
    }
  }
  .copy-cont {
    width: 100%;
    margin-top: var(--spacing) ;
    padding-top: var(--spacing);
    
    @media ${device.mobileL} {
      border-top: none;
    }
    
    .copy {
      display: flex;
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      flex-direction: column;
      
      li {
        margin-right: var(--spacing);
      }
      
      @media ${device.mobileL} {
        justify-content: center;
        flex-direction: row;
        
        li:not(:first-child){
          border-left: 4px solid rgba(255, 255, 255, 0.4);
          padding-left: var(--spacing);
        }
        li {
          margin: 0 calc(var(--spacing) / 2);
          font-size: 0.75rem;
        }
      }
    }
  }
`

export default Footer
