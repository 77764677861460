import { createGlobalStyle } from "styled-components"
import {device} from "./breakpoints"
import "@fontsource/crimson-pro"
import "@fontsource/crimson-pro/700.css"
import "@fontsource/grenze-gotisch"
import "@fontsource/grenze-gotisch/900.css"

const TypographyStyles = createGlobalStyle`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    line-height: 1.4;
  }

  h1,
  h2 {
    font-family: "Grenze Gotisch";
    color: #29A6B5;
    font-size: 1.6rem;
    font-weight: 900;
    margin: 1.5rem 0;

    @media ${device.tablet} {
      font-size: 1.6rem;
      line-height: 1.3;
    }

    @media ${device.laptop} {
      font-size: 2rem;
    }
  }

  h3 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.4;
    margin: 1rem 0;

    @media ${device.tablet} {
      font-size: 1.1rem;
    }

    @media ${device.laptop} {
      font-size: 1.2rem;
      line-height: 1.6;
    }
  }
`
export default TypographyStyles
