export const mainMenuItems = [
  //{
  //  path: "/",
  //  text: "advertise",
  //},
  {
    path: "/",
    text: "home",
  },
  {
    path: "/beta-signup",
    text: "beta signup",
  },
]
