import {createGlobalStyle} from "styled-components"
import {device} from "./breakpoints"

const GlobalStyles = createGlobalStyle`
:root {
    --heavyWeight: 900;
    --transMed: 0.3s;
    --transSlow: 0.5s;
    --charcoal: #444;
    --primaryColor: #a2e3eb;
    --h1: 2rem;
    --h2: 1.8rem;
    --h3: 1.5rem;
    --h4: 1.25rem;
    --h5: 1.15rem;
    --h6: 1rem;
    --footerMenuItem: 0.85rem;
    --para: 1rem;
    --spacing: 1rem;

    @media ${device.tablet} {
      --h1: 2.6rem;
      --h2: 2.4rem;
      --h3: 1.75rem;
      --h4: 1.5rem;
      --h5: 1.25rem;
      --h6: 1.1rem;
      --footerMenuItem: 1rem;
      --para: 1.1rem;
    }

    @media ${device.laptop} {
      --h1: 3rem;
      --h2: 2.8rem;
      --h3: 2rem;
      --h4: 1.75rem;
      --h5: 1.35rem;
      --para: 1.15rem;
    }
  }

* {
    box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Crimson Pro", Georgia, 'Times New Roman', Times, serif;
  font-weight: 400;
  color: var(--charcoal);
  ${'' /*background-color: #060c21; */}
  background: #bbb;
}

a {
  color: var(--charcoal)
}

.layout{
  background-color: #ffffffcc;
  margin: 0 auto;
  padding: 10px 10px 100px 10px;
  max-width: 700px;
}

@media ${device.tablet}{
  .layout {
    padding: 10px 20px 100px 20px;

  }
}

.pageBgWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
}

.pageBg {
  width: 100vw;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  background-attachment: fixed;
}

 /* opacity changing overlay */
.pageBgWrapper::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(255,255,255,.3);
}

.beta-screenshot {
  padding: .5rem 2rem 0 2rem;
}

@media ${device.tablet} {
  .beta-screenshot {
    padding: 1.5rem 1rem 0 .2rem;
  }
}

.beta-container {
  display: flex;
  flex-direction: column-reverse;
}

@media ${device.tablet} {
  .beta-container {
    flex-direction: row;
  }
}
  
.content-container {
  margin: 0 auto;
  width: 100%;
  max-width: 500px;

  @media ${device.laptop} {
    max-width: 650px;
  }
}

.text-center {
  text-align: center;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important; /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; /* 3 */
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}
`
export default GlobalStyles
