import React from "react"
import {FaInstagram, FaFacebook } from "react-icons/fa"

export const mainMenuItems = [
  {
    path: "/",
    title: "home",
  },
]

export const socialMenuItems = [
  {
    icon: <FaInstagram />,
    url: "https://www.instagram.com/its.mask.fashions",
    name: "Instagram",
  },
  {
    icon: <FaFacebook />,
    url: "https://www.facebook.com/maskfashionsapp",
    name: "Facebook",
  },
]

export const footerMenuItems = [
  {
    path: "/privacy-policy",
    title: "privacy policy",
  },
  {
    path: "/terms-and-conditions",
    title: "terms & conditions",
  },
]
