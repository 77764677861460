import React,{useState} from "react"
import {mainMenuItems} from "../constants/links"
import styled from "styled-components"
import {Link} from "gatsby"
import {device} from '../styles/breakpoints'


const Navbar = ({Logo}) => {
  const [isOpen,setNav] = useState(false)

  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }
  return (
    <NavStyles>
      <div className="masthead">
        <Link className='logolink' to="/">
          <div className="logotext">mask fashions</div>
          <div className="tagline">Look good. Be safe.</div>
        </Link>
      </div>
        <button
          className={isOpen ? "toggle-btn toggle-btn-active" : "toggle-btn"}
          type="button"
          onClick={toggleNav}
          aria-label="Menu Button"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
      <ul className={isOpen ? "nav-links show-nav" : "nav-links"}>
        {mainMenuItems.map((item,index) => {
          return (
            <li key={index}>
              <a href={item.path}>{item.text}</a>
            </li>
          )
        })}
      </ul>
    </NavStyles>
  )
}

export const NavStyles = styled.nav`
  z-index: 10;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: var(--primaryColor);

  .masthead {

    .logolink {
      text-decoration: none;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .logotext {
        font-size: 1rem;
        font-family: "Grenze Gotisch";
        font-weight: 900;
        color: var(--charcoal);
    }


    @media ${device.mobileS} {
      .logotext {
        font-size: 1.5rem;
      }
    }

    @media ${device.laptop} {
      .logotext {
        font-size: 2.5rem;
      }
    }
  }

  /*  default for all @media not specified below */
  .nav-links {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: center;
    margin: 0;
    background-color: var(--charcoal);
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    /* move out of way unless @mobile matches below */
    transform: translateX(100%); 
    transition: none;
    list-style: none;

    li {
      list-style: none;
      font-size: 1.3rem;
      font-weight: 400;
      margin: 0;
      /*height: fit-content;*/
      padding-right: 10px;
      a {
        text-decoration: none;
        text-transform: capitalize;
        transition: 0.1s;

        &.active {
          color: #000;
        }
      }
      &:hover {
        cursor: pointer;
        a {
          color: #000;
        }
      }
    }

    &.show-nav {
      transform: translateX(0%);
      display: flex;
      position: fixed;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding-top: 2rem;
      a {
        color: #fff;
      }
      li {
        font-size: 1.8rem;
        padding: 1rem;
      }
    }
  }

  .toggle-btn {
    z-index: 300;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 10px;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      transition: 0.2s ease-in;

      &:nth-child(1) {
        transform: translateY(-5px);
      }

      &:nth-child(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-child(1) {
          transform: translateY(2px) rotate(45deg);
        }

        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }

        &:nth-child(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  /* show full menu */
  @media ${device.tablet} {
    .toggle-btn {
      display: none;
    }

    .nav-links {
      position: absolute;
      display: flex;
      background: transparent;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      left: 0;
      right: 0;
      /* move out the way */
      transform: translateX(0);
      transition: none;
      height: fit-content;
      
      li {
        margin: 1rem;
        font-size: 1.5rem;
      }
    }
  }
`

export default Navbar
